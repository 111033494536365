import React from "react";
import { useField } from "formik";

import { Form, Row, Col, Button } from "react-bootstrap";

const MyTextInput = ({ label, helpText, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);

  return (
    <Form.Group as={Row} className="mb-4">
      <Form.Label column md={3} htmlFor={props.id || props.name}>
        {label}
      </Form.Label>
      <Col md={8}>
        <Form.Control
          {...field}
          {...props}
          isInvalid={meta.touched && meta.error}
        />
        {meta.touched && meta.error ? (
          <Form.Control.Feedback type="invalid">
            {meta.error}
          </Form.Control.Feedback>
        ) : null}
        {helpText && <Form.Text muted>{helpText}</Form.Text>}
      </Col>
    </Form.Group>
  );
};

const MyRadio = ({ label, helpText, options, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <Form.Group as={Row} className="journal__form__frequency">
      <Form.Label column md={3}>
        {label}
      </Form.Label>
      <Col md={8}>
        <>
          {options.map((option, index) => (
            <Form.Check
              key={index}
              {...field}
              inline
              type="radio"
              label={option.title}
              value={option.value}
              checked={option.value === parseInt(field.value)}
              isInvalid={meta.touched && meta.error}
            />
          ))}
          {meta.touched && meta.error ? (
            <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
              {meta.error}
            </Form.Control.Feedback>
          ) : (
            <br />
          )}
          {helpText && <Form.Text muted>{helpText}</Form.Text>}
          <br />
          <br />
        </>
      </Col>
    </Form.Group>
  );
};

const MySelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <Form.Group as={Row} className="mb-4">
        <Form.Label column md={3}>
          {label}
        </Form.Label>
        <Col md={8}>
          <Form.Select
            {...field}
            {...props}
            isInvalid={meta.touched && meta.error}
          />
          {meta.touched && meta.error ? (
            <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
              {meta.error}
            </Form.Control.Feedback>
          ) : null}
        </Col>
      </Form.Group>
    </>
  );
};

const MyButtonInput = ({ label, callback, buttonText }) => {
  return (
    <Row className="journal__form__sideEffects">
      <Col md={3}>
        {label}
      </Col>
      <Col md={8}>
        <Button variant="outline-primary" onClick={callback}>
          {buttonText}
        </Button>
      </Col>
    </Row>
  );
};

const MyCheckList = ({ label, helpText, options, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      {options.map((option, index) => (
        <Form.Check
          key={index}
          {...field}
          type="checkbox"
          label={option.title}
          value={option.value}
          checked={field.value.includes(option.value)}
          isInvalid={meta.touched && meta.error}
        />
      ))}
      {meta.touched && meta.error ? (
        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
          {meta.error}
        </Form.Control.Feedback>
      ) : (
        <br />
      )}
      {helpText && <Form.Text muted>{helpText}</Form.Text>}
      <br />
      <br />
    </>
  );
};

export { MyTextInput, MyRadio, MySelect, MyCheckList, MyButtonInput };
