import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useCookieConsents } from "@enzsft/react-cookie-consents";

import { store } from "./redux/store";

import Routes from "./routes/Routes";
import { Header } from "./components";

import { updateHash, updateLang } from "./redux/features/userData";

import config from "./config/config.json";

const App = ({ match }) => {
  const dispatch = useDispatch();
  const { trackPageView } = useMatomo();
  const hash = match.params.hash;
  const cookieConsents = useCookieConsents();
  const consent = cookieConsents.get();

  if (store.getState().userData.hash === null) {
    dispatch(updateHash(hash));
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "prod" && consent[0] === "analytics") {
      trackPageView();
    }
  }, [trackPageView, consent]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.forEach(function (value, key) {
      console.log(value, key);
      if (key === "lang" && Object.keys(config["languages"]).includes(value)) {
        dispatch(updateLang(value));
      }
    });
  }, [dispatch]);

  return (
    <>
      <Header />
      <Routes />
    </>
  );
};

export default App;
