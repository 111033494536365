import React from "react";
import { useSelector } from "react-redux";

import Text from "../../assets/json/strings.json";

import "./PhenotypeGraphic.scss";

const PhenotypeGraphic = ({ value }) => {
  const { lang } = useSelector((state) => state.userData);

  return (
    <>
      <div className="phenotypegraphic">
        <div
          className={
            "phenotypegraphic__box " +
            // eslint-disable-next-line
            (value == 1 && "phenotypegraphic__box--active")
          }
        >
          <p>{Text[lang].genes.phenotypeResult.VR}</p>
        </div>
        <div
          className={
            "phenotypegraphic__box " +
            // eslint-disable-next-line
            (value == 2 && "phenotypegraphic__box--active")
          }
        >
          <p>{Text[lang].genes.phenotypeResult.R}</p>
        </div>
        <div
          className={
            "phenotypegraphic__box " +
            // eslint-disable-next-line
            (value == 3 && "phenotypegraphic__box--active")
          }
        >
          <p>{Text[lang].genes.phenotypeResult.N}</p>
        </div>
        <div
          className={
            "phenotypegraphic__box " +
            // eslint-disable-next-line
            (value == 4 && "phenotypegraphic__box--active")
          }
        >
          <p>{Text[lang].genes.phenotypeResult.I}</p>
        </div>
      </div>
    </>
  );
};

export default PhenotypeGraphic;
