import React from "react";
import Markdown from "react-markdown";

import { Accordion } from "react-bootstrap";

import Text from "../../assets/json/strings.json";

import "./DrivingItem.scss";

const DrivingItem = ({ title, content, hasImpact, eventKey, lang }) => {
  return (
    <Accordion.Item
      eventKey={eventKey}
      className={
        "driving__accordion__item " +
        (hasImpact && "driving__accordion__item--impact")
      }
    >
      <Accordion.Header
        className={
          "driving__accordion__item__title " +
          (hasImpact && "driving__accordion__item__title--impact")
        }
      >
        {title}
      </Accordion.Header>
      <Accordion.Body>
        {hasImpact ? (
          <Markdown>{content}</Markdown>
        ) : (
          Text[lang].pages.driving.noImpact
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default DrivingItem;
