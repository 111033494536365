import React, { useState } from "react";
import yellow from "../../assets/img/icons/overall_yellow.svg";
import green from "../../assets/img/icons/overall_green.svg";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";

import Text from "../../assets/json/strings.json";

import "./DependencyTable.scss";
import { CustomModal } from "..";

const TableItem = ({ hasImpact, children, isGene, setmodaldata, setmodal }) => {
  const { lang } = useSelector((state) => state.userData);

  const handleClick = () => {
    setmodaldata({
      title: children,
      content: Text[lang].pages.dependency.genes[children],
    });
    setmodal(true);
  };

  return (
    <div
      className={"tableItem " + (hasImpact && "tableItem--impact")}
      style={isGene && { fontStyle: "italic", cursor: "pointer" }}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

const DependencyTable = ({ data }) => {
  const { lang } = useSelector((state) => state.userData);

  const [modal, setmodal] = useState(false);
  const [modaldata, setmodaldata] = useState({});

  return (
    <>
      <div className="depTableContainer">
        <div className="depTable">
          <div className="depTable__header">
            <ul>
              <li>{Text[lang].pages.dependency.tableGene}</li>
              <li>{Text[lang].pages.dependency.tableVariant}</li>
              <li></li>
            </ul>
          </div>
          <div className="depTable__content">
            {data?.slice(0, data?.length / 2).map((row, index) => (
              <ul className="depTable__content__row" key={index}>
                <TableItem
                  hasImpact={row.impact}
                  isGene
                  setmodaldata={setmodaldata}
                  setmodal={setmodal}
                >
                  {row.gene}
                </TableItem>
                <TableItem hasImpact={row.impact}>{row.ensembl}</TableItem>
                <img
                  src={row.impact ? yellow : green}
                  alt=""
                  className="depTable__content__row__icon"
                />
              </ul>
            ))}
          </div>
        </div>

        <div className="depTable">
          <div className="depTable__header">
            <ul>
              <li>{Text[lang].pages.dependency.tableGene}</li>
              <li>{Text[lang].pages.dependency.tableVariant}</li>
              <li></li>
            </ul>
          </div>
          <div className="depTable__content">
            {data.slice(data?.length / 2, data?.length).map((row, index) => (
              <ul className="depTable__content__row" key={index}>
                <TableItem
                  hasImpact={row.impact}
                  isGene
                  setmodaldata={setmodaldata}
                  setmodal={setmodal}
                >
                  {row.gene}
                </TableItem>
                <TableItem hasImpact={row.impact}>{row.ensembl}</TableItem>
                <img
                  src={row.impact ? yellow : green}
                  alt=""
                  className="depTable__content__row__icon"
                />
              </ul>
            ))}
          </div>
        </div>
      </div>
      <CustomModal modal={modal} setmodal={setmodal} modalData={modaldata}>
        <Markdown>{modaldata.content}</Markdown>
      </CustomModal>
    </>
  );
};

export default DependencyTable;
