import React from "react";
import { useSelector } from "react-redux";

import { GenesTable, SectionCard, TrafficLight, Loading } from "../../../components";

import Text from "../../../assets/json/strings.json";

const CBD = () => {
  const { data, isLoading } = useSelector((state) => state.reportData);
  const { lang } = useSelector((state) => state.userData);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h2>{Text[lang].pages.cbd.title}</h2>
          <div style={{
              margin: "2em 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <h5
              style={{
                marginBottom: "0.8em",
                fontSize: "1rem",
              }}
            >
              {Text[lang].pages.cbd.mainResult}
            </h5>
            <TrafficLight variant={parseInt(data.cbd?.overall_score)} main />
          </div>
          <hr />

          <SectionCard type="tolerance" data={data.cbd?.tolerance} />

          <hr />
          <GenesTable data={data.cbd?.genes} />
        </>
      )}
    </>
  );
};

export default CBD;
