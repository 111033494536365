import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { CookieConsentsProvider } from "@enzsft/react-cookie-consents";

import { store } from "./redux/store";

import "./styles/main.scss";

import App from "./App";
import { NoMatch } from "./pages";

var instance = null;

if (process.env.REACT_APP_ENV === "prod") {
  instance = createInstance({
    urlBase: `${process.env.REACT_APP_FRONTEND_HOST}/webapp`,
    siteId: process.env.REACT_APP_MATOMO_SITE_ID,
    trackerUrl: `${process.env.REACT_APP_MATOMO_HOST}/matomo.php`,
    srcUrl: `${process.env.REACT_APP_MATOMO_HOST}/matomo.js`,
    linkTracking: true,
  });
}

ReactDOM.render(
  <MatomoProvider value={instance}>
    <CookieConsentsProvider cookieName="cookieConsents" expiryInDays={365}>
      <Router basename="/webapp">
        <Switch>
          <Route path="/ups" component={NoMatch} />
          <Provider store={store}>
            <Route path="/:hash" component={App} />
          </Provider>
        </Switch>
      </Router>
    </CookieConsentsProvider>
  </MatomoProvider>,
  document.getElementById("root")
);

// Check that service workers are supported
if (
  (process.env.REACT_APP_ENV === "apptest" ||
    process.env.REACT_APP_ENV === "prod") &&
  "serviceWorker" in navigator
) {
  // Use the window load event to keep the page load performant
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.js");
  });
}
