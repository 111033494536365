import React from "react";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";

import { BackButton, TrafficLight } from "../../../../components";

import Tolerance from "../../../../assets/img/icons/tolerance.svg";
import Text from "../../../../assets/json/strings.json";

const CBDData = ({ location }) => {
  const { data } = location.state;

  const { lang } = useSelector((state) => state.userData);

  var title = Text[lang].pages.cbd.toleranceCard;
  var icon = Tolerance;

  return (
    <div className="thcdata">
      <BackButton />
      <h3>{title}</h3>
      <div className="thcdata__graphic">
        <img src={icon} alt="" className="thcdata__graphic__icon" />
        <TrafficLight variant={parseInt(data.score)} main />
      </div>
      <div className="thcdata__text">
        {data.text.map((item, index) => (
          <Markdown key={index}>{item}</Markdown>
        ))}
      </div>
    </div>
  );
};

export default CBDData;
