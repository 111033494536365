import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";

import {
  addJournalEntry,
  getJournalEntries,
} from "../../redux/features/journal";

import {
  MyTextInput,
  MyRadio,
  MySelect,
  MyCheckList,
  CustomModal,
  MyButtonInput,
} from "..";

import { Button, Col, Row } from "react-bootstrap";

import Text from "../../assets/json/strings.json";

import "./JournalForm.scss";

const ProductFields = () => {
  const {
    values: { product },
  } = useFormikContext();

  const { lang } = useSelector((state) => state.userData);

  return (
    <>
      {product && (
        <>
          <hr />
          <MyTextInput
            label={Text[lang].pages.journal.parameters.thcValue[product].title}
            name="thcValue"
            type="number"
            helpText={
              Text[lang].pages.journal.parameters.thcValue[product].help
            }
          />
          <MyTextInput
            label={Text[lang].pages.journal.parameters.cbdValue[product].title}
            name="cbdValue"
            type="number"
            helpText={
              Text[lang].pages.journal.parameters.cbdValue[product].help
            }
          />
          <MyRadio
            label={Text[lang].pages.journal.parameters.frequency.title}
            name="frequency"
            helpText={Text[lang].pages.journal.parameters.frequency.help}
            options={[
              { title: "1", value: parseInt(1) },
              { title: "2", value: 2 },
              { title: "3", value: 3 },
              { title: "4", value: 4 },
              { title: "5", value: 5 },
              { title: "6", value: 6 },
              { title: "7", value: 7 },
            ]}
          />
          <MyTextInput
            label={Text[lang].pages.journal.parameters.dosage[product].title}
            name="dosage"
            type="number"
            helpText={Text[lang].pages.journal.parameters.dosage[product].help}
          />
          <hr />
        </>
      )}
    </>
  );
};

const JournalForm = () => {
  const dispatch = useDispatch();

  const [modal, setmodal] = useState(false);

  const { hash, lang } = useSelector((state) => state.userData);

  const validationSchema = Yup.object({
    administration: Yup.string()
      .oneOf(
        ["inhalation", "ingestion"],
        Text[lang].pages.journal.validation.invalidAdmin
      )
      .required(Text[lang].pages.journal.validation.required),
    product: Yup.string()
      .oneOf(
        ["flowers", "extract"],
        Text[lang].pages.journal.validation.invalidProd
      )
      .required(Text[lang].pages.journal.validation.required),
    dosage: Yup.number().required(Text[lang].pages.journal.validation.required),
    thcValue: Yup.number().required(
      Text[lang].pages.journal.validation.required
    ),
    cbdValue: Yup.number().required(
      Text[lang].pages.journal.validation.required
    ),
    frequency: Yup.number()
      .oneOf(
        [1, 2, 3, 4, 5, 6, 7],
        Text[lang].pages.journal.validation.invalidFreq
      )
      .required(Text[lang].pages.journal.validation.required),
    sideEffects: Yup.array().of(Yup.string()),
    manufacturer: Yup.string(),
    productName: Yup.string(),
    notes: Yup.string(),
  });

  const handleSubmit = (values, e) => {
    let date = new Date();
    dispatch(addJournalEntry({ ...values, date: date.toISOString(), hash }))
      .unwrap()
      .then(() => {
        dispatch(getJournalEntries(hash));
      });
    e.resetForm();
  };

  return (
    <>
      <Formik
        initialValues={{
          administration: "",
          product: "",
          dosage: "",
          thcValue: "",
          cbdValue: "",
          frequency: "",
          sideEffects: [],
          manufacturer: "",
          productName: "",
          notes: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <MySelect
              label={Text[lang].pages.journal.parameters.administration.title}
              name="administration"
            >
              <option value="">
                {Text[lang].pages.journal.parameters.administration.select}
              </option>
              <option value="inhalation">
                {Text[lang].pages.journal.parameters.administration.inhalation}
              </option>
              <option value="ingestion">
                {Text[lang].pages.journal.parameters.administration.ingestion}
              </option>
            </MySelect>

            <MySelect
              label={Text[lang].pages.journal.parameters.product.title}
              name="product"
            >
              <option value="">
                {Text[lang].pages.journal.parameters.administration.select}
              </option>
              <option value="flowers">
                {Text[lang].pages.journal.parameters.product.flowers}
              </option>
              <option value="extract">
                {Text[lang].pages.journal.parameters.product.extract}
              </option>
            </MySelect>

            <ProductFields />

            <MyButtonInput
              label={Text[lang].pages.journal.parameters.sideEffects.title}
              buttonText={Text[lang].pages.journal.parameters.sideEffects.add}
              callback={() => setmodal(true)}
            />

            <CustomModal
              modal={modal}
              setmodal={setmodal}
              modalData={{
                title: Text[lang].pages.journal.parameters.sideEffects.title,
              }}
            >
              <MyCheckList
                label={Text[lang].pages.journal.parameters.sideEffects.title}
                name="sideEffects"
                options={
                  Text[lang].pages.journal.parameters.sideEffects.options
                }
              />
            </CustomModal>

            <MyTextInput
              label={Text[lang].pages.journal.parameters.manufacturer.title}
              name="manufacturer"
            />

            <MyTextInput
              label={Text[lang].pages.journal.parameters.productName.title}
              name="productName"
            />

            <MyTextInput
              label={Text[lang].pages.journal.parameters.notes.title}
              name="notes"
              as="textarea"
            />

            <Row>
              <Col md={3}></Col>
              <Col md={8}>
                <Button type="submit">{Text[lang].pages.journal.submit}</Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default JournalForm;
