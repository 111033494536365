import React from "react";
import { useSelector } from "react-redux";

import { Modal, Button } from "react-bootstrap";

import Text from "../../assets/json/strings.json";

import "./CustomModal.scss";

const CustomModal = ({ modal, setmodal, modalData, onYes, children }) => {
  const { lang } = useSelector((state) => state.userData);

  return (
    <>
      <Modal centered show={modal} onHide={() => setmodal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{modalData.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { children }
          {modalData.actions && (
            <div className="modal__buttons">
              <Button variant="outline-primary" onClick={() => setmodal(false)}>
                {Text[lang].pages.journal.remove.no}
              </Button>
              <Button variant="primary" onClick={() => onYes(modalData.data)}>
                {Text[lang].pages.journal.remove.yes}
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CustomModal;
