import React from "react";
import { useSelector } from "react-redux";

import { Col, Row } from "react-bootstrap";

import {
  GenesTable,
  SectionCard,
  TrafficLight,
  Loading,
} from "../../../components";

import Text from "../../../assets/json/strings.json";

const THC = () => {
  const { data, isLoading } = useSelector((state) => state.reportData);
  const { lang } = useSelector((state) => state.userData);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h2>{Text[lang].pages.thc.title}</h2>
          <div
            style={{
              margin: "2em 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h5
              style={{
                marginBottom: "0.8em",
                fontSize: "1rem",
              }}
            >
              {Text[lang].pages.thc.mainResult}
            </h5>
            <TrafficLight variant={parseInt(data.thc?.overall_score)} main />
          </div>
          <hr />
          <Row xs={1} className="g-4 mb-4">
            <Col>
              <SectionCard type="tolerance" data={data.thc?.tolerance} />
            </Col>
          </Row>
          <Row xs={1} sm={2} className="g-4">
            <Col>
              <SectionCard type="inhalation" data={data.thc?.inhalation} />
            </Col>
            <Col>
              <SectionCard type="ingestion" data={data.thc?.ingestion} />
            </Col>
          </Row>
          <hr />
          <GenesTable data={data.thc?.genes} />
          <p style={{ fontSize: "0.8em", lineHeight: "1.2" }}>
            <sup>1</sup> {Text[lang].pages.thc.footnote}
          </p>
        </>
      )}
    </>
  );
};

export default THC;
