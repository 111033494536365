import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import { TrafficLight } from "..";

import { Card } from "react-bootstrap";

import Tolerance from "../../assets/img/icons/tolerance.svg";
import Inhalation from "../../assets/img/icons/inhalation.svg";
import Ingestion from "../../assets/img/icons/ingestion.svg";

import Text from "../../assets/json/strings.json";
import "./SectionCard.scss";

const SectionCard = ({ type, data, location }) => {
  const history = useHistory();

  const { lang } = useSelector((state) => state.userData);

  var area = location.pathname.split("/").slice(-1)[0];

  var icon;
  var text = "";

  switch (type) {
    case "tolerance":
      icon = Tolerance;
      text = Text[lang].pages[area].toleranceCard;
      break;
    case "inhalation":
      icon = Inhalation;
      text = Text[lang].pages[area].inhalationCard;
      break;
    case "ingestion":
      icon = Ingestion;
      text = Text[lang].pages[area].ingestionCard;
      break;
    default:
      break;
  }

  return (
    <>
      <Card
        className="mainInfo"
        onClick={() =>
          history.push({
            pathname: `${history.location.pathname}/${type}`,
            state: {
              data: data,
            },
          })
        }
      >
        <Card.Body className="mainInfo__card">
          <img src={icon} alt="" className="mainInfo__card__icon" />
          <h5 className="mainInfo__title">{text}</h5>
          <TrafficLight variant={parseInt(data?.score)} />
        </Card.Body>
      </Card>
    </>
  );
};

export default withRouter(SectionCard);
