import React from "react";
import { Spinner } from "react-bootstrap";

import './Loading.scss'

const Loading = () => {
  return (
    <div className="loading">
      <Spinner animation="grow" variant="primary"/>
      <h5>Loading Data</h5>
    </div>
  );
};

export default Loading;
