import React from "react";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";

import { BackButton, TrafficLight } from "../../../../components";

import Tolerance from "../../../../assets/img/icons/tolerance.svg";
import Inhalation from "../../../../assets/img/icons/inhalation.svg";
import Ingestion from "../../../../assets/img/icons/ingestion.svg";

import Text from "../../../../assets/json/strings.json";

import "./THCData.scss";

const THCData = ({ match, location }) => {
  const { lang } = useSelector((state) => state.userData);

  const { data } = location.state;

  const area = match.params.area;

  var title = "";
  var icon;

  switch (area) {
    case "tolerance":
      title = Text[lang].pages.thc.toleranceCard;
      icon = Tolerance;
      break;

    case "inhalation":
      title = Text[lang].pages.thc.inhalationCard;
      icon = Inhalation;
      break;

    case "ingestion":
      title = Text[lang].pages.thc.ingestionCard;
      icon = Ingestion;
      break;

    default:
      break;
  }

  return (
    <div className="thcdata">
      <BackButton />
      <h3>{title}</h3>
      <div className="thcdata__graphic">
        <img src={icon} alt="" className="thcdata__graphic__icon" />
        <TrafficLight variant={parseInt(data.score)} main />
      </div>
      <div className="thcdata__text">
        {data.text.map((item, index) => (
          <Markdown key={index}>{item}</Markdown>
        ))}
      </div>
    </div>
  );
};

export default THCData;
