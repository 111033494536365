import React from "react";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";

import { Loading, DrivingItem } from "../../../components";

import { Accordion, Container } from "react-bootstrap";

import Text from "../../../assets/json/strings.json";

const Behaviour = () => {
  const { isLoading, data } = useSelector((state) => state.reportData);
  const { lang } = useSelector((state) => state.userData);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h2>{Text[lang].pages.behaviour.title}</h2>
          <br />
          <p>{Text[lang].pages.behaviour.helpText}</p>

          <Container className="driving">
            <Accordion className="driving__accordion">
              {data?.behaviour?.texts.map((item, index) => (
                <DrivingItem
                  key={index}
                  eventKey={index}
                  title={Text[lang].pages.behaviour.cards[item.subarea]}
                  content={item.text}
                  hasImpact={item.impact}
                  lang={lang}
                />
              ))}
            </Accordion>
          </Container>

          <hr style={{ margin: "2em 0" }} />

          <h5>{Text[lang].pages.behaviour.recommendations}</h5>
          {data?.behaviour?.general_text.map((text, index) => (
            <Markdown key={index}>{text}</Markdown>
          ))}
        </>
      )}
    </>
  );
};

export default Behaviour;
