import React from "react";
import { DependencyTable, Loading, ProgressBar } from "../../../components";
import { useSelector } from "react-redux";
import green from "../../../assets/img/icons/overall_green.svg";
import yellow from "../../../assets/img/icons/overall_yellow.svg";

import Markdown from "react-markdown";

import Text from "../../../assets/json/strings.json";

const Dependency = () => {
  const { lang } = useSelector((state) => state.userData);
  const { data, isLoading } = useSelector((state) => state.reportData);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h2>{Text[lang].pages.dependency.title}</h2>
          <ProgressBar value={data?.dependency?.value} />
          <p>
            {Text[lang].pages.dependency.infoText.beforeImg1}
            <img src={yellow} alt="" style={{ maxWidth: "1.5em" }} />
            {Text[lang].pages.dependency.infoText.beforeImg2}
            <img src={green} alt="" style={{ maxWidth: "1.5em" }} />
            {Text[lang].pages.dependency.infoText.afterImg}
          </p>
          <br />
          <DependencyTable data={data?.dependency?.impact_genes} />
          <hr style={{ margin: "2em 0" }} />

          <h5>{Text[lang].pages.dependency.recommendations}</h5>
          <Markdown>{data?.dependency?.text}</Markdown>
        </>
      )}
    </>
  );
};

export default Dependency;
