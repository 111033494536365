import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getJournalEntries } from "../../../redux/features/journal";

import { Accordion } from "react-bootstrap";

import { JournalForm, JournalTable } from "../../../components";

import Text from "../../../assets/json/strings.json";

import "./Journal.scss";

const Journal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { hash, lang } = useSelector((state) => state.userData);

  useEffect(() => {
    dispatch(getJournalEntries(hash))
      .unwrap()
      .catch(() => {
        history.push(`/${hash}`);
      });
  }, [dispatch, hash, history]);

  return (
    <>
      <h2>{Text[lang].pages.journal.title}</h2>
      <br />
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {Text[lang].pages.journal.new}
          </Accordion.Header>
          <Accordion.Body>
            <JournalForm />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />
      <JournalTable />
    </>
  );
};

export default Journal;
