import { configureStore } from "@reduxjs/toolkit";
import journalReducer from "./features/journal";
import reportDataReducer from "./features/reportData";
import userDataReducer from "./features/userData";

export const store = configureStore({
  reducer: {
    journal: journalReducer,
    userData: userDataReducer,
    reportData: reportDataReducer,
  },
});
