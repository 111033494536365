import axios from "axios";

const getJournalEntriesAPI = async (hash) => {
  const URL = `${process.env.REACT_APP_BACKEND_HOST}/backend/journal/${hash}/entries`;

  try {
    const response = await axios({
      method: "GET",
      url: URL,
    });
    return response;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

const addJournalEntryAPI = async ({ hash, ...data }) => {
  const URL = `${process.env.REACT_APP_BACKEND_HOST}/backend/journal/${hash}/new`;

  try {
    const response = await axios({
      method: "POST",
      url: URL,
      data: data,
    });
    return response;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

const removeJournalEntryAPI = async ({ hash, id }) => {
  const URL = `${process.env.REACT_APP_BACKEND_HOST}/backend/journal/${hash}/remove`;

  try {
    const response = await axios({
      method: "DELETE",
      url: URL,
      data: { id: id },
    });
    return response;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

export { getJournalEntriesAPI, addJournalEntryAPI, removeJournalEntryAPI };
