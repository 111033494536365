import React from "react";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";

import { BackButton, PhenotypeGraphic } from "../../../components";

import Text from "../../../assets/json/strings.json";

import "./Genes.scss";

const Genes = ({ match, location }) => {
  const { lang } = useSelector((state) => state.userData);
  const score = location.state.score;
  const result = location.state.result;
  const phenotype_text = location.state.phenotype_text;

  return (
    <>
      <div className="genes">
        <BackButton />
        <h2>{match.params.gene}</h2>
        <Markdown>{Text[lang].genes[match.params.gene]}</Markdown>
        <hr />
        <h4>{Text[lang].genes.results}</h4>
        <p>{result}</p>
        <div className="genes__results">
          <PhenotypeGraphic value={score} />
          <h6>{phenotype_text}</h6>
        </div>
        <br />
        <p style={{ fontSize: "0.8em" }}>{Text[lang].genes.phenotypeLabel}</p>
        {match.params.gene === "UGT1A1" && (
          <p style={{ fontSize: "0.8em", lineHeight: "1.2" }}>
            {Text[lang].pages.thc.footnote}
          </p>
        )}
      </div>
    </>
  );
};

export default Genes;
