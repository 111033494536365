import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Navbar, Container, Offcanvas, ListGroup, Form } from "react-bootstrap";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";

import { updateLang } from "../../redux/features/userData";

import Logo from "../../assets/img/icons/cannabis.svg";
import Text from "../../assets/json/strings.json";
import config from "../../config/config.json";

import "./Header.scss";

const Header = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { hash, lang } = useSelector((state) => state.userData);

  const [menuIsOpen, setmenuIsOpen] = useState(false);

  const checkLocation = (target) => {
    return location.pathname.split("/").slice(-1)[0] === target;
  };

  const handleChangeLang = (e) => {
    dispatch(updateLang(e.target.value));
    history.push("/webapp/" + hash);
    setmenuIsOpen(false);
  };

  return (
    <>
      <Navbar expand={false} fixed="top" className="header">
        <Container style={{ maxWidth: "70em" }}>
          <Navbar.Brand className="header__brand" href={`/webapp/${hash}`}>
            {/* <FontAwesomeIcon
              className="header__brand__icon"
              icon={faCannabis}
            /> */}
            <img src={Logo} alt="" className="header__brand__icon" />
            <p className="header__brand__text">MyCannabisGenes</p>
          </Navbar.Brand>
          <Navbar.Toggle
            className="header__toggle"
            onClick={() => setmenuIsOpen(true)}
          >
            <FontAwesomeIcon icon={faGripVertical} />
          </Navbar.Toggle>
          <Offcanvas
            id="basic-navbar-nav"
            placement="end"
            show={menuIsOpen}
            onHide={() => setmenuIsOpen(false)}
            className="header__offcanvas"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title className="header__offcanvas__title">
                MyCannabisGenes
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Form.Select onChange={handleChangeLang} value={lang}>
                {Object.keys(config.languages).map((l, i) => (
                  <option key={i} value={l}>
                    {config.languages[l].text}
                  </option>
                ))}
              </Form.Select>
              <hr />
              <ListGroup variant="flush" className="header__list">
                <ListGroup.Item
                  active={checkLocation("help")}
                  onClick={() => {
                    history.push(`/${hash}/help`);
                    setmenuIsOpen(false);
                  }}
                >
                  {Text[lang].menus.help.title}
                </ListGroup.Item>
                <ListGroup.Item
                  active={checkLocation("installation")}
                  onClick={() => {
                    history.push(`/${hash}/installation`);
                    setmenuIsOpen(false);
                  }}
                >
                  {Text[lang].menus.installation.nav}
                </ListGroup.Item>
                <ListGroup.Item
                  active={checkLocation("cookies")}
                  onClick={() => {
                    history.push(`/${hash}/cookies`);
                    setmenuIsOpen(false);
                  }}
                >
                  {Text[lang].menus.cookies.title}
                </ListGroup.Item>
                <ListGroup.Item
                  active={checkLocation("terms")}
                  onClick={() => {
                    history.push(`/${hash}/terms`);
                    setmenuIsOpen(false);
                  }}
                >
                  {Text[lang].menus.terms.title}
                </ListGroup.Item>
                <ListGroup.Item
                  active={checkLocation("refs")}
                  onClick={() => {
                    history.push(`/${hash}/refs`);
                    setmenuIsOpen(false);
                  }}
                >
                  {Text[lang].menus.refs.title}
                </ListGroup.Item>
              </ListGroup>
            </Offcanvas.Body>
          </Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default withRouter(Header);
