import React from "react";
import { useSelector } from "react-redux";

import Text from "../../assets/json/strings.json";

import "./ProgressBar.scss";

const ProgressBar = ({ value }) => {
  const width = value * 100 + "%";

  const { lang } = useSelector((state) => state.userData);

  const localText = Text[lang].pages.dependency;

  return (
    <div className="progressBarContainer">
      <div className="progressBar">
        <div className="progressBarValue" style={{ width: width }}></div>
      </div>
      <div className="scale">
        <div className="scaleLine">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <ul className="percentages">
          <li>0%</li>
          <li>25%</li>
          <li>50%</li>
          <li>75%</li>
          <li>100%</li>
        </ul>
        <div className="risk-text">
          <p>{localText.lowRisk}</p>
          <p>{localText.highRisk}</p>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
