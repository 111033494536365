import React from "react";
import { useSelector } from "react-redux";

import Text from "../../assets/json/strings.json";

const Cookies = () => {
  const { lang } = useSelector((state) => state.userData);
  return (
    <>
      <h2>{Text[lang].menus.cookies.title}</h2>
      <br />
      <div
        dangerouslySetInnerHTML={{ __html: Text[lang].menus.cookies.content }}
      />
    </>
  );
};

export default Cookies;
