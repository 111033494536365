import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CustomModal, Loading } from "..";

import { Button, Table } from "react-bootstrap";

import {
  removeJournalEntry,
  getJournalEntries,
} from "../../redux/features/journal";

import Text from "../../assets/json/strings.json";
import config from "../../config/config.json";

import { faTrash } from "@fortawesome/free-solid-svg-icons";

import "./JournalTable.scss";

const JournalTable = () => {
  const dispatch = useDispatch();

  const [modal, setmodal] = useState(false);
  const [modalData, setmodalData] = useState({});

  const { records, isLoading } = useSelector((state) => state.journal);
  const { hash, lang } = useSelector((state) => state.userData);

  const openSideEffects = (e, effects) => {
    e.preventDefault();
    var sideEffects = "";
    effects.map((item, index) => {
      if (index < effects.length - 1) {
        return (sideEffects +=
          Text[lang].pages.journal.parameters.sideEffects.options.find(
            (object) => object.value === item
          ).title + ", ");
      } else {
        return (sideEffects += Text[
          lang
        ].pages.journal.parameters.sideEffects.options.find(
          (object) => object.value === item
        ).title);
      }
    });
    setmodalData({
      title: Text[lang].pages.journal.parameters.sideEffects.title,
      content: sideEffects,
    });
    setmodal(true);
  };

  const openNotes = (e, notes) => {
    e.preventDefault();
    setmodalData({
      title: Text[lang].pages.journal.parameters.notes.title,
      content: notes,
    });
    setmodal(true);
  };

  const handleRemoveEntry = (e, id) => {
    e.preventDefault();
    setmodalData({
      title: Text[lang].pages.journal.remove.title,
      content: Text[lang].pages.journal.remove.body,
      actions: true,
      data: id,
    });
    setmodal(true);
  };

  const onRemoveEntry = (id) => {
    dispatch(removeJournalEntry({ hash, id }))
      .unwrap()
      .then(() => {
        dispatch(getJournalEntries(hash));
      });
    setmodal(false);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Table responsive className="journaltable">
          <thead>
            <tr>
              <th>{Text[lang].pages.journal.parameters.date}</th>
              <th>{Text[lang].pages.journal.parameters.frequency.title}</th>
              <th>{Text[lang].pages.journal.parameters.dosage.title.thc}</th>
              <th>{Text[lang].pages.journal.parameters.dosage.title.cbd}</th>
              <th>
                {Text[lang].pages.journal.parameters.administration.title}
              </th>
              <th>{Text[lang].pages.journal.parameters.sideEffects.title}</th>
              <th>{Text[lang].pages.journal.parameters.manufacturer.title}</th>
              <th>{Text[lang].pages.journal.parameters.productName.title}</th>
              <th>{Text[lang].pages.journal.parameters.notes.title}</th>
              <th>{Text[lang].pages.journal.parameters.delete}</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record, index) => {
              let newDate = new Date(record.date);
              let options = {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
              };
              return (
                <tr key={index}>
                  <td>
                    {newDate.toLocaleDateString(
                      config.languages[lang].locale,
                      options
                    )}
                  </td>
                  <td>
                    {record.frequency +
                      " " +
                      Text[lang].pages.journal.parameters.frequency.help}
                  </td>
                  <td>{record.thc}</td>
                  <td>{record.cbd}</td>
                  <td>
                    {
                      Text[lang].pages.journal.parameters.administration[
                        record.administration
                      ]
                    }
                  </td>
                  <td>
                    {record.sideEffects.length === 0 ? (
                      Text[lang].pages.journal.parameters.sideEffects.empty
                    ) : (
                      <Button
                        size="sm"
                        onClick={(e) => openSideEffects(e, record.sideEffects)}
                      >
                        {Text[lang].pages.journal.parameters.sideEffects.open}
                      </Button>
                    )}
                  </td>
                  <td>
                    {record.manufacturer === ""
                      ? Text[lang].pages.journal.parameters.manufacturer.empty
                      : record.manufacturer}
                  </td>
                  <td>
                    {record.productName === ""
                      ? Text[lang].pages.journal.parameters.productName.empty
                      : record.productName}
                  </td>
                  <td>
                    {record.notes === "" ? (
                      Text[lang].pages.journal.parameters.notes.empty
                    ) : (
                      <Button
                        size="sm"
                        onClick={(e) => openNotes(e, record.notes)}
                      >
                        {Text[lang].pages.journal.parameters.notes.open}
                      </Button>
                    )}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={(e) => handleRemoveEntry(e, record._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <CustomModal
        modal={modal}
        setmodal={setmodal}
        modalData={modalData}
        onYes={onRemoveEntry}
      >
        <p>{modalData.content}</p>
      </CustomModal>
    </>
  );
};

export default JournalTable;
