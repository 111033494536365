import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { PhenotypeGraphic } from "..";

import { Button, Table } from "react-bootstrap";

import Text from "../../assets/json/strings.json";

import "./GenesTable.scss";

const GenesTable = ({ data }) => {
  const { hash, lang } = useSelector((state) => state.userData);
  const history = useHistory();
  return (
    <>
      <Table responsive="sm" className="genestable">
        <thead>
          <tr>
            <th>{Text[lang].genes.substrate}</th>
            <th>{Text[lang].genes.gene}</th>
            <th>{Text[lang].genes.product}</th>
            <th colSpan={2}>{Text[lang].genes.phenotype}</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((gene, index) => (
            <tr key={index}>
              <td>{gene.substract}</td>
              <td>
                <Button
                  variant="link"
                  className="genestable__link"
                  onClick={() =>
                    history.push({
                      pathname: `/${hash}/genes/` + gene.gene,
                      state: {
                        score: gene.score,
                        result: gene.result,
                        phenotype_text: gene.phenotype_text,
                      },
                    })
                  }
                >
                  {gene.gene === "UGT1A1" ? (
                    <>
                      {gene.gene}
                      <sup>1</sup>
                    </>
                  ) : (
                    gene.gene
                  )}
                </Button>
              </td>
              <td>{gene.product}</td>
              <td>{gene.phenotype_text}</td>
              <td>
                <PhenotypeGraphic value={gene.score} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* <br /> */}
      <div style={{ textAlign: "right", fontSize: "0.7em" }}>
        <p>{Text[lang].genes.phenotypeLabel}</p>
      </div>
    </>
  );
};

export default GenesTable;
