import React from "react";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";

import Text from "../../../assets/json/strings.json";
import GreenIcon from "../../../assets/img/icons/overall_green.svg";
import YellowIcon from "../../../assets/img/icons/overall_yellow.svg";
import RedIcon from "../../../assets/img/icons/overall_red.svg";

import "./Help.scss";

const Help = () => {
  const { lang } = useSelector((state) => state.userData);
  return (
    <>
      <h2>{Text[lang].menus.help.title}</h2>
      <br />
      <p>{Text[lang].menus.help.content.p1}</p>
      <br />
      {Object.keys(Text[lang].menus.help.content.label).map((item, index) => (
        <React.Fragment key={index}>
          <div className="help__label">
            <div className="help__label__image">
              <img
                src={
                  item === "green"
                    ? GreenIcon
                    : item === "yellow"
                    ? YellowIcon
                    : item === "red"
                    ? RedIcon
                    : null
                }
                alt=""
              />
            </div>
            <p>{Text[lang].menus.help.content.label[item]}</p>
          </div>
        </React.Fragment>
      ))}
      <br />
      <Markdown>{Text[lang].menus.help.content.p2}</Markdown>
    </>
  );
};

export default Help;
