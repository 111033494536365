import React from "react";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import rehypeRaw from "rehype-raw";

import {
  Accordion,
  useAccordionButton,
  AccordionContext,
} from "react-bootstrap";

import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import Text from "../../../assets/json/strings.json";

import "./Information.scss";

function CustomToggle({ children, eventKey, callback }) {
  const { activeEventKey } = React.useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div className="toggle" onClick={decoratedOnClick}>
      {isCurrentEventKey ? (
        <FontAwesomeIcon icon={faCaretDown} />
      ) : (
        <FontAwesomeIcon icon={faCaretRight} />
      )}
      <h5>{children}</h5>
    </div>
  );
}

const Information = () => {
  const { lang } = useSelector((state) => state.userData);
  const { data } = useSelector((state) => state.reportData);

  const isExtended =
    data?.dependency !== undefined && data?.dependency !== null;

  return (
    <>
      <h2>{Text[lang].pages.information.title}</h2>
      <br />

      <Accordion defaultActiveKey={0}>
        {Object.keys(Text[lang].pages.information.topics).map(
          (topic, index) => {
            if ((topic === "impact" || topic === "chronic") && !isExtended) {
              return null;
            }
            return (
              <div key={index}>
                <CustomToggle eventKey={index}>
                  {Text[lang].pages.information.topics[topic].title}
                </CustomToggle>
                <Accordion.Collapse eventKey={index}>
                  <Markdown
                    rehypePlugins={[rehypeRaw]}
                    children={
                      Text[lang].pages.information.topics[topic].content
                    }
                  />
                </Accordion.Collapse>
                <br />
              </div>
            );
          }
        )}
      </Accordion>

      {/* {Object.keys(Text[lang].pages.information.topics).map((topic, index) => (
        <div key={index}>
          <h5>{Text[lang].pages.information.topics[topic].title}</h5>
          <Markdown>
            {Text[lang].pages.information.topics[topic].content}
          </Markdown>
          <br />
        </div>
      ))} */}
    </>
  );
};

export default Information;
