import React from "react";

import Red from "../../assets/img/icons/red.svg";
import Yellow from "../../assets/img/icons/yellow.svg";
import Green from "../../assets/img/icons/green.svg";

import "./TrafficLight.scss";

const TrafficLight = ({ variant, main }) => {
  let icon;

  switch (variant) {
    case 1:
      icon = Red;
      break;
    case 2:
      icon = Yellow;
      break;
    case 3:
      icon = Green;
      break;

    default:
      break;
  }

  return (
    <>
      <div className={"trafficLight " + (main && "trafficLight--main")}>
        <img src={icon} alt="" />
      </div>
    </>

    // <div className={"trafficLight " + (main && "trafficLight--main")}>
    //   <div
    //     className={
    //       "trafficLight__circle " +
    //       (variant === 1 && "trafficLight__circle--active")
    //     }
    //   ></div>
    //   <div
    //     className={
    //       "trafficLight__circle " +
    //       (variant === 2 && "trafficLight__circle--active")
    //     }
    //   ></div>
    //   <div
    //     className={
    //       "trafficLight__circle " +
    //       (variant === 3 && "trafficLight__circle--active")
    //     }
    //   ></div>
    // </div>
  );
};

export default TrafficLight;
