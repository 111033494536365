import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getConsentAPI, putConsentAPI } from "../../api/Data";

import config from "../../config/config.json";

const lang = localStorage.getItem("lang");

const initialState = {
  hash: null,
  hashIsValid: true,
  lang: lang ? lang : Object.keys(config.languages)[0],
  consent: null,
};

export const getConsent = createAsyncThunk(
  "userData/getConsent",
  async (hash, thunkAPI) => {
    try {
      const response = await getConsentAPI(hash);
      return response.data.consent;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const putConsent = createAsyncThunk(
  "userData/putConsent",
  async (data, thunkAPI) => {
    try {
      const response = await putConsentAPI(data);
      return response.data.consent;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

const data = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateHash: (state, action) => {
      state.hash = action.payload;
    },
    validateHash: (state, action) => {
      state.hashIsValid = action.payload;
    },
    updateLang: (state, action) => {
      localStorage.setItem("lang", action.payload);
      state.lang = action.payload;
    },
  },
  extraReducers: {
    [getConsent.fulfilled]: (state, action) => {
      state.consent = action.payload;
    },
    [getConsent.rejected]: (state) => {
      state.consent = null;
    },
    [putConsent.fulfilled]: (state, action) => {
      state.consent = action.payload;
    },
    [putConsent.rejected]: (state) => {
      state.consent = null;
    },
  },
});

export const { updateHash, validateHash, updateLang } = data.actions;
export default data.reducer;
