import React from "react";
import { useSelector } from "react-redux";

import Text from "../../../assets/json/strings.json";
import "./Terms.scss";

const Terms = () => {
  const { lang } = useSelector((state) => state.userData);
  return (
    <>
      <h2>{Text[lang].menus.terms.title}</h2>
      <br />
      <div
        dangerouslySetInnerHTML={{ __html: Text[lang].menus.terms.content }}
      />
    </>
  );
};

export default Terms;
