import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Stack } from "react-bootstrap";

import Text from "../../../assets/json/strings.json";

import "./Dashboard.scss";

const Dashboard = () => {
  const history = useHistory();

  const { hash, lang } = useSelector((state) => state.userData);
  const { data } = useSelector((state) => state.reportData);
  return (
    <>
      <Stack gap={3}>
        <Button
          className="dashboard__item"
          onClick={() => history.push(`/${hash}/thc`)}
        >
          {Text[lang].navigation.thc}
        </Button>
        <Button
          className="dashboard__item"
          onClick={() => history.push(`/${hash}/cbd`)}
        >
          {Text[lang].navigation.cbd}
        </Button>
        <Button
          className="dashboard__item"
          onClick={() => history.push(`/${hash}/driving`)}
        >
          {Text[lang].navigation.driving}
        </Button>
        {data.dependency && (
          <>
            <Button
              className="dashboard__item"
              onClick={() => history.push(`/${hash}/behaviour`)}
            >
              {Text[lang].navigation.behaviour}
            </Button>
            <Button
              className="dashboard__item"
              onClick={() => history.push(`/${hash}/dependency`)}
            >
              {Text[lang].navigation.dependency}
            </Button>
          </>
        )}
        <Button
          className="dashboard__item"
          onClick={() => history.push(`/${hash}/info`)}
        >
          {Text[lang].navigation.information}
        </Button>
        <Button
          className="dashboard__item"
          onClick={() => history.push(`/${hash}/journal`)}
        >
          {Text[lang].navigation.journal}
        </Button>
      </Stack>
    </>
  );
};

export default Dashboard;
