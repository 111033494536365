import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addJournalEntryAPI, getJournalEntriesAPI, removeJournalEntryAPI } from "../../api/Journal";

const initialState = {
  records: [],
  isLoading: false,
};

export const getJournalEntries = createAsyncThunk(
  "journal/entries",
  async (hash, thunkApi) => {
    try {
      const response = await getJournalEntriesAPI(hash);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue();
    }
  }
);

export const addJournalEntry = createAsyncThunk(
  "journal/add",
  async (data, thunkApi) => {
    try {
      const response = await addJournalEntryAPI(data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue();
    }
  }
);

export const removeJournalEntry = createAsyncThunk(
  "journal/remove",
  async (data, thunkApi) => {
    try {
      const response = await removeJournalEntryAPI(data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue();
    }
  }
);

const journal = createSlice({
  name: "journal",
  initialState,
  reducers: {},
  extraReducers: {
    [getJournalEntries.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.records = action.payload.journalEntries;
    },
    [getJournalEntries.pending]: (state) => {
      state.isLoading = true;
    },
    [getJournalEntries.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

// export const {} = journal.actions;
export default journal.reducer;
