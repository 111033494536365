import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Col, Container, Row } from "react-bootstrap";

import { useWindowWidth } from "../helpers/isMobile";

import { Footer, Navigation, CookieBanner } from "../components";

import { getReportData } from "../redux/features/reportData";
import { validateHash } from "../redux/features/userData";

import {
  Help,
  Installation,
  Cookies,
  Terms,
  Refs,
  THC,
  CBD,
  Information,
  THCData,
  CBDData,
  Genes,
  Journal,
  Driving,
  Dashboard,
  Behaviour,
  Dependency,
} from "../pages";

const Routes = () => {
  const isMobile = useWindowWidth();
  const { hash, hashIsValid, lang } = useSelector((state) => state.userData);

  const dispatch = useDispatch();
  const { error, data } = useSelector((state) => state.reportData);

  let isDash = window.location.pathname.split("/").length === 3;

  useEffect(() => {
    dispatch(getReportData({ hash, lang }));
  }, [dispatch, hash, lang]);

  useEffect(() => {
    if (error) {
      dispatch(validateHash(false));
    }
  }, [error, dispatch]);

  return (
    <>
      <Container style={{ maxWidth: "70em" }}>
        <Row>
          {!isMobile && (
            <Col md={3} lg={2} style={{ padding: 0 }}>
              <Navigation />
            </Col>
          )}
          <Col xs={12} md={9} lg={8} className="app__mainContainer">
            <Container>
              <Switch>
                {!hashIsValid && <Redirect to={`/ups`} />}

                <Route exact path="/:hash/help" component={Help} />
                <Route
                  exact
                  path="/:hash/installation"
                  component={Installation}
                />
                <Route exact path="/:hash/cookies" component={Cookies} />
                <Route exact path="/:hash/terms" component={Terms} />
                <Route exact path="/:hash/refs" component={Refs} />

                <Route exact path="/:hash/thc" component={THC} />
                <Route exact path="/:hash/thc/:area" component={THCData} />

                <Route exact path="/:hash/cbd" component={CBD} />
                <Route exact path="/:hash/cbd/:area" component={CBDData} />

                <Route exact path="/:hash/driving" component={Driving} />

                {data.behaviour && (
                  <Route exact path="/:hash/behaviour" component={Behaviour} />
                )}

                {data.dependency && (
                  <Route
                    exact
                    path="/:hash/dependency"
                    component={Dependency}
                  />
                )}

                <Route exact path="/:hash/info" component={Information} />

                <Route exact path="/:hash/journal" component={Journal} />

                <Route exact path="/:hash/genes/:gene" component={Genes} />

                {isMobile && (
                  <Route exact path="/:hash" component={Dashboard} />
                )}
                <Route path="/:hash">
                  <>
                    {isMobile ? (
                      <Redirect to={`/${hash}`} />
                    ) : (
                      <Redirect to={`/${hash}/thc`} />
                    )}
                  </>
                </Route>
              </Switch>
              <Route
                exact
                path={[
                  "/:hash/thc",
                  "/:hash/cbd",
                  "/:hash/driving",
                  "/:hash/info",
                  "/:hash/journal",
                ]}
                render={() => <CookieBanner />}
              />
            </Container>
            {isMobile && !isDash && <Footer />}
          </Col>
          {!isMobile && <Col md={0} lg={2} style={{ padding: 0 }}></Col>}
        </Row>
      </Container>
    </>
  );
};

export default Routes;
