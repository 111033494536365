import React from "react";
import { useSelector } from "react-redux";

import Text from "../../assets/json/strings.json";

const Installation = () => {
  const { lang } = useSelector((state) => state.userData);
  return (
    <>
      <h2>{Text[lang].menus.installation.title}</h2>
      <br />
      <h5>{Text[lang].menus.installation.android.title}</h5>
      <ul>
        {Text[lang].menus.installation.android.steps.map((step, index) => (
          <li key={index}>{step}</li>
        ))}
      </ul>
      <h5>{Text[lang].menus.installation.ios.title}</h5>
      <ul>
        {Text[lang].menus.installation.ios.steps.map((step, index) => (
          <li key={index}>{step}</li>
        ))}
      </ul>
    </>
  );
};

export default Installation;
