import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

import "./Footer.scss";

const Footer = () => {
  const history = useHistory();

  const { hash } = useSelector((state) => state.userData);
  return (
    <>
      <div className="footer__container">
        <Button
          className="footer__button"
          onClick={() => history.push(`/${hash}`)}
        >
          <FontAwesomeIcon icon={faHome} />
        </Button>
      </div>
    </>
  );
};

export default Footer;
