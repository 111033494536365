import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import { ListGroup } from "react-bootstrap";
import Sticky from "react-stickynode-update";

import Text from "../../assets/json/strings.json";

import "./Navigation.scss";

const Navigation = ({ location }) => {
  const history = useHistory();

  const { hash, lang } = useSelector((state) => state.userData);
  const { data } = useSelector((state) => state.reportData);

  const checkLocation = (target) => {
    return location.pathname.split("/").includes(target);
  };

  return (
    <>
      <div className="navigation">
        <Sticky className="navigation__sticky">
          <ListGroup variant="flush" className="navigation__list">
            <ListGroup.Item
              active={checkLocation("thc")}
              onClick={() => history.push(`/${hash}/thc`)}
            >
              {Text[lang].navigation.thc}
            </ListGroup.Item>
            <ListGroup.Item
              active={checkLocation("cbd")}
              onClick={() => history.push(`/${hash}/cbd`)}
            >
              {Text[lang].navigation.cbd}
            </ListGroup.Item>
            <ListGroup.Item
              active={checkLocation("driving")}
              onClick={() => history.push(`/${hash}/driving`)}
            >
              {Text[lang].navigation.driving}
            </ListGroup.Item>
            {data.behaviour && (
              <ListGroup.Item
                active={checkLocation("behaviour")}
                onClick={() => history.push(`/${hash}/behaviour`)}
              >
                {Text[lang].navigation.behaviour}
              </ListGroup.Item>
            )}
            {data.dependency && (
              <ListGroup.Item
                active={checkLocation("dependency")}
                onClick={() => history.push(`/${hash}/dependency`)}
              >
                {Text[lang].navigation.dependency}
              </ListGroup.Item>
            )}
            <ListGroup.Item
              active={checkLocation("info")}
              onClick={() => history.push(`/${hash}/info`)}
            >
              {Text[lang].navigation.information}
            </ListGroup.Item>
            <ListGroup.Item
              active={checkLocation("journal")}
              onClick={() => history.push(`/${hash}/journal`)}
            >
              {Text[lang].navigation.journal}
            </ListGroup.Item>
          </ListGroup>
        </Sticky>
      </div>
    </>
  );
};

export default withRouter(Navigation);
