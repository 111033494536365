import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookieConsents } from "@enzsft/react-cookie-consents";
import * as Yup from "yup";

import { getConsent, putConsent } from "../../redux/features/userData";

import { Modal } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { Form as BSForm, Button } from "react-bootstrap";

import Text from "../../assets/json/strings.json";

const CookieBanner = () => {
  const dispatch = useDispatch();
  const cookieConsents = useCookieConsents();

  const { hash, consent, lang } = useSelector((state) => state.userData);

  const handleSubmit = (values) => {
    if (values.analytics) cookieConsents.add("analytics");
    else cookieConsents.add("essential");

    dispatch(putConsent({ hash, consent: values.dataCollected }));
  };

  const validationSchema = Yup.object({
    analytics: Yup.boolean(),
    dataCollected: Yup.boolean(),
    consent: Yup.boolean(),
  });

  useEffect(() => {
    dispatch(getConsent(hash));
  }, [dispatch, hash]);

  if (cookieConsents.get().length > 0) {
    return null;
  }

  return (
    <>
      <Modal show={true} backdrop="static" centered>
        <Modal.Header>
          <Modal.Title>{Text[lang].consent.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{Text[lang].consent.intro}</p>
          <Formik
            initialValues={{
              analytics: true,
              dataCollected: true,
              consent: false,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <BSForm.Check
                  disabled
                  type="switch"
                  label={Text[lang].consent.necessary}
                  checked={true}
                />

                <Field name="analytics">
                  {({ field }) => (
                    <BSForm.Check
                      type="switch"
                      id="analytics"
                      label={Text[lang].consent.analytics}
                      {...field}
                      checked={field.value}
                    />
                  )}
                </Field>

                <hr />

                {consent === null && (
                  <>
                    <Field name="dataCollected">
                      {({ field }) => (
                        <BSForm.Check
                          type="checkbox"
                          id="dataCollected"
                          label={Text[lang].consent.dataCollection}
                          {...field}
                          checked={field.value}
                        />
                      )}
                    </Field>

                    <hr />
                  </>
                )}

                <Field name="consent">
                  {({ field }) => (
                    <BSForm.Check
                      type="checkbox"
                      id="consent"
                      label={Text[lang].consent.consent}
                      {...field}
                      checked={field.value}
                    />
                  )}
                </Field>

                <br />

                <div dangerouslySetInnerHTML={{__html: Text[lang].consent.link}} />

                <Button type="submit" disabled={!values.consent}>
                  {Text[lang].consent.button}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CookieBanner;
