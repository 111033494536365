import React from "react";
import { useSelector } from "react-redux";

import Text from "../../assets/json/strings.json";

const Refs = () => {
  const { lang } = useSelector((state) => state.userData);
  const { data } = useSelector((state) => state.reportData);

  const isExtended =
    data?.dependency !== undefined && data?.dependency !== null;

  return (
    <>
      <h2>{Text[lang].menus.refs.title}</h2>
      <br />
      <ul>
        {isExtended ? (
          <>
            {Text[lang].menus.refs.contentExt.map((ref, index) => (
              <React.Fragment key={index}>
                <li>{ref}</li>
                <br />
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            {Text[lang].menus.refs.content.map((ref, index) => (
              <React.Fragment key={index}>
                <li>{ref}</li>
                <br />
              </React.Fragment>
            ))}
          </>
        )}
      </ul>
    </>
  );
};

export default Refs;
