import axios from "axios";

const getReportDataAPI = async (hash, lang) => {
  const URL = `${process.env.REACT_APP_BACKEND_HOST}/backend/data/${hash}/report-data?lang=${lang}`;

  try {
    const response = await axios({
      method: "GET",
      url: URL,
    });
    return response;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

const getDataAPI = async (hash, category) => {
  const URL = `${process.env.REACT_APP_BACKEND_HOST}/backend/data/${hash}/${category}`;

  try {
    const response = await axios({
      method: "GET",
      url: URL,
    });
    return response;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

const getConsentAPI = async (hash) => {
  const URL = `${process.env.REACT_APP_BACKEND_HOST}/backend/data/${hash}/consent`;

  try {
    const response = await axios({
      method: "GET",
      url: URL,
    });
    return response;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

const putConsentAPI = async ({ hash, consent }) => {
  const URL = `${process.env.REACT_APP_BACKEND_HOST}/backend/data/${hash}/consent`;

  try {
    const response = await axios({
      method: "PUT",
      url: URL,
      data: {
        consent,
      },
    });
    return response;
  } catch (error) {
    console.log(error.message);
    throw error;
  }
};

export { getReportDataAPI, getDataAPI, getConsentAPI, putConsentAPI };
