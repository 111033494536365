import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDataAPI, getReportDataAPI } from "../../api/Data";

const initialState = {
  data: {},
  isLoading: false,
  error: false,
};

export const getCategoryData = createAsyncThunk(
  "categoryData",
  async ({ hash, category }, thunkApi) => {
    try {
      const categoryData = await getDataAPI(hash, category);
      if (
        categoryData.data.hasOwnProperty("overall_score") ||
        categoryData.data.hasOwnProperty("score")
      ) {
        return categoryData.data;
      } else {
        return thunkApi.rejectWithValue();
      }
    } catch (error) {
      return thunkApi.rejectWithValue();
    }
  }
);

export const getReportData = createAsyncThunk(
  "reportData",
  async ({ hash, lang }, thunkApi) => {
    try {
      const categoryData = await getReportDataAPI(hash, lang);
      if (categoryData.data.hasOwnProperty("thc")) {
        return categoryData.data;
      } else {
        return thunkApi.rejectWithValue();
      }
    } catch (error) {
      return thunkApi.rejectWithValue();
    }
  }
);

const reportData = createSlice({
  name: "reportData",
  initialState,
  reducers: {},
  extraReducers: {
    [getCategoryData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state[action.meta.arg.category] = action.payload;
    },
    [getCategoryData.pending]: (state) => {
      state.isLoading = true;
    },
    [getCategoryData.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
    },

    [getReportData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    [getReportData.pending]: (state) => {
      state.isLoading = true;
    },
    [getReportData.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
    },
  },
});

// export const {} = reportData.actions;
export default reportData.reducer;
