import React from "react";

import { useHistory } from "react-router-dom";

import { Button } from "react-bootstrap";

import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./BackButton.scss";

const BackButton = () => {
  const history = useHistory();
  return (
    <>
      <Button className="backbutton" onClick={() => history.goBack()}>
        <FontAwesomeIcon icon={faBackward} />
      </Button>
    </>
  );
};

export default BackButton;
